import React from "react"
import { Layout, SectionContainer, SectionTitle } from "../components/_layout"

const ErrorPage: React.FC = () => {
  return (
    <Layout withNav seoTitle="Error" seoDescription="Esta página no existe.">
      <SectionContainer sectionID="error404">
        <SectionTitle title="Esta página no existe" />
      </SectionContainer>
    </Layout>
  )
}

export default ErrorPage
